import { createSlice } from '@reduxjs/toolkit';

export type Address = {
  address1?: string | undefined | null;
  address2?: string | undefined | null;
  city?: string | undefined | null;
  state?: string | undefined | null;
  county?: string | undefined | null;
  country?: string | undefined | null;
  zipCode?: string | undefined | null;
};

export type Profile = {
  _id: string;
  username: string;
  name: string;
  roleIds: string[];
  email: string;
  emailVerified: boolean;
  phone?: string | undefined | null;
  phoneVerified?: boolean;
  address?: Address | undefined | null;
  profilePhotoId?: string | undefined | null;
  profilePhotoThumbUrl?: string | undefined | null;
  createdAt: string;
  updatedAt: string;
};

interface AuthState {
  token: string | undefined;
  profile: Profile | undefined;
}

const initialState: AuthState = {
  token: undefined,
  profile: undefined,
};

const authSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    saveToken(state, action) {
      state.token = action.payload.token;
      localStorage.setItem('authToken', action.payload.token);
    },
    deleteToken(state, action) {
      state.token = undefined;
      localStorage.removeItem('authToken');
    },
    updateProfile(state, action) {
      state.profile = action.payload.profile;
    },
    deleteProfile(state, action) {
      state.profile = undefined;
    },
  },
});

export const { saveToken, deleteToken, updateProfile, deleteProfile } =
  authSlice.actions;
export default authSlice.reducer;
