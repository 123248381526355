// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAp9vcGJhKW2Q_4-11vmgkMW_eDzZlkrR8',
  authDomain: 'where-do-i-sit.firebaseapp.com',
  projectId: 'where-do-i-sit',
  storageBucket: 'where-do-i-sit.appspot.com',
  messagingSenderId: '410686392114',
  appId: '1:410686392114:web:c2db594a5e9e4e0ed8b92b',
  measurementId: 'G-65WCPWS2JY',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
