import CssBaseline from '@mui/joy/CssBaseline';
import { CssVarsProvider, extendTheme, PaletteRange } from '@mui/joy/styles';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import RootRouter from './routers/RootRouter';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';

const materialTheme = materialExtendTheme();

const router = createBrowserRouter([{ path: '*', Component: RootRouter }]);

interface Color {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
  solidBg: string;
  solidActiveBg: string;
  outlinedBorder: string;
  outlinedColor: string;
  outlinedActiveBg: string;
  softColor: string;
  softBg: string;
  softActiveBg: string;
  plainColor: string;
  plainActiveBg: string;
}

declare module '@mui/joy/styles' {
  interface ColorPalettePropOverrides {
    // apply to all Joy UI components that support `color` prop
  }

  interface Palette {
    // this will make the node `secondary` configurable in `extendTheme`
    // and add `secondary` to the theme's palette.
  }
}

const theme = extendTheme({
  // fontFamily: {
  //   // display: 'Mohave Variable', // applies to `h1`–`h4`
  //   // body: 'Noto Sans', // applies to `title-*` and `body-*`
  // },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: '#e7f3f4',
          100: '#c4e2e2',
          200: '#9ad2d1',
          300: '#6fc1bf',
          400: '#53b3b0',
          500: '#43a5a0',
          600: '#3e9792',
          700: '#398781',
          800: '#347671',
          900: '#2a5a53',
        },
        success: {
          50: '#e6f6e8',
          100: '#c4e9c8',
          200: '#9ddba4',
          300: '#72ce7f',
          400: '#4fc362',
          500: '#23b845',
          600: '#17a83c',
          700: '#009630',
          800: '#008525',
          900: '#006511',
        },
        danger: {
          50: '#ffeaee',
          100: '#ffcbd2',
          200: '#f59599',
          300: '#ed6a71',
          400: '#f9424d',
          500: '#ff2731',
          600: '#f01831',
          700: '#de012b',
          800: '#d10023',
          900: '#c30015',
        },
        warning: {
          50: '#fffae6',
          100: '#fef2c0',
          200: '#fcec97',
          300: '#f9e46e',
          400: '#f5dd4d',
          500: '#f2d828',
          600: '#e2c624',
          700: '#ceb11e',
          800: '#ba9a19',
          900: '#99750f',
        },
      },
    },
  },
});

function App() {
  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <CssVarsProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <RouterProvider router={router} />
        </LocalizationProvider>
      </CssVarsProvider>
    </MaterialCssVarsProvider>
  );
}

export default App;
