import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, Divider, IconButton, Typography } from '@mui/joy';
import { useCallback, useEffect, useState } from 'react';
import logo from '../assets/short-logo.png';
import PublicPageTemplate from '../components/PublicPageTemplate';
import useSafeState from '../shared/use-safe-state';
import KidsForm from './KidsForm';
import { Car } from './shared';
import CarRows from './CarRows';

function shuffle(array: any) {
  let currentIndex = array.length;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
}

function HomePage() {
  const [car, _setCar, _car] = useSafeState<Car>({
    rows: [
      {
        seats: 3,
      },
    ],
    kids: [],
  });
  const [showSetup, _setShowSetup] = useState<boolean>(true);
  const setShowSetup = useCallback((value: boolean) => {
    localStorage.setItem('showSetup', JSON.stringify({ showSetup: value}));
    _setShowSetup(value);
  }, []);
  useEffect(() => {
    const existing = localStorage.getItem('car');
    if (existing) {
      try {
        _setCar(JSON.parse(existing));
      } catch (err) {
        console.error(err);
      }
    }
    const existingSetup = localStorage.getItem('showSetup');
    if (existingSetup) {
      try {
        _setShowSetup(JSON.parse(existingSetup).showSetup);
      } catch (err) {
        console.error(err);
      }
    }
  }, [_setCar]);
  const setCar = useCallback(
    (value: Car) => {
      localStorage.setItem('car', JSON.stringify(value));
      _setCar(value);
    },
    [_setCar],
  );

  const [ordered, setOrdered] = useState<string[][]>([]);
  useEffect(() => {
    const names = car.kids.map((k) => k.name);
    shuffle(names);
    let seats: string[][] = [];
    let i = 0;
    car.rows.forEach((row) => {
      let seatsRow: string[] = [];
      Array(row.seats)
        .fill(0)
        .forEach(() => {
          seatsRow.push(names[i]);
          i += 1;
        });
      seats.push(seatsRow);
    });
    setOrdered(seats);
  }, [car]);

  return (
    <PublicPageTemplate title="Where do I sit?">
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          minHeight: '100%',
          flexDirection: 'column',
          paddingTop: 32,
          marginRight: '5vw',
          marginLeft: '5vw',
          // background: 'linear-gradient(45deg, rgba(242,242,242,1) 40%, rgba(255,255,255,1) 60%, rgba(255,235,235,1) 100%)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography level="h1" sx={{ mb: 2 }}>
            Where do I sit?
          </Typography>
          <img
            src={logo}
            alt="Logo"
            style={{ width: 300, marginBottom: 16, maxWidth: '50vw' }}
          />
        </Box>
        <Box sx={{}}>
          <KidsForm
            car={car}
            _car={_car}
            setCar={setCar}
            showSetup={showSetup}
          />

          <CarRows
            car={car}
            _car={_car}
            setCar={setCar}
            showSetup={showSetup}
            ordered={ordered}
          />
        </Box>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Box>
          {showSetup ? (
            <Button
              variant="solid"
              color="primary"
              onClick={() => setShowSetup(false)}
              style={{ marginRight: 16 }}
            >
              Hide Setup
            </Button>
          ) : (
            <Button
              variant="solid"
              color="primary"
              onClick={() => setShowSetup(true)}
              style={{ marginRight: 16 }}
            >
              Show Setup
            </Button>
          )}
        </Box>
      </Box>
    </PublicPageTemplate>
  );
}

export default HomePage;
