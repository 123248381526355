import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, IconButton, Typography } from '@mui/joy';
import { Car } from './shared';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface Props {
  car: Car;
  setCar: any;
  _car: any;
  showSetup: any;
  ordered: string[][];
}

function CarRows({ car, setCar, _car, showSetup, ordered }: Props) {
  const onAddRow = () => {
    setCar({ ...car, rows: [...car.rows, { seats: 3 }] });
  };

  const onRemoveRow = () => {
    setCar({ ...car, rows: car.rows.slice(0, car.rows.length - 1) });
  };

  const onAddSeat = (rowIndex: number) => {
    const row = car.rows[rowIndex];
    setCar({
      ...car,
      rows: [
        ...car.rows.slice(0, rowIndex),
        { seats: row.seats + 1 },
        ...car.rows.slice(rowIndex + 1),
      ],
    });
  };

  const onRemoveSeat = (rowIndex: number) => {
    const row = car.rows[rowIndex];
    setCar({
      ...car,
      rows: [
        ...car.rows.slice(0, rowIndex),
        { seats: row.seats - 1 },
        ...car.rows.slice(rowIndex + 1),
      ],
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mb: 2,
      }}
    >
      <Typography level="h2" sx={{ mb: 2, textAlign: 'center' }}>
        Seating
      </Typography>
      {car.rows.map((row, index) => (
        <Box
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            overflow: 'auto',
            maxWidth: '90vw',
          }}
          key={index}
        >
          {Array(row.seats)
            .fill(0)
            .map((_, index2) => (
              <Box
                key={index2}
                style={{
                  height: 100,
                  width: 100,
                  backgroundColor: 'var(--joy-palette-primary-50)',
                  border: '2px solid #625b5c',
                  borderRadius: 8,
                  marginRight: 8,
                  marginBottom: 8,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 8,
                  flexShrink: 0,
                }}
              >
                <Typography
                  sx={{
                    textAlign: 'center',
                    textWrap: 'pretty',
                    overflowWrap: 'break-word',
                    fontWeight: 700,
                  }}
                >
                  {ordered[index]?.[index2]}
                </Typography>
              </Box>
            ))}
          {showSetup && (
            <>
              <IconButton
                variant="solid"
                color="primary"
                onClick={() => onAddSeat(index)}
                style={{ marginRight: 8 }}
              >
                <AddIcon />
              </IconButton>
              {row.seats > 1 && (
                <IconButton
                  variant="outlined"
                  color="primary"
                  onClick={() => onRemoveSeat(index)}
                >
                  <RemoveIcon />
                </IconButton>
              )}
            </>
          )}
        </Box>
      ))}
      {showSetup ? (
        <Box style={{ display: 'flex', marginTop: 16 }}>
          <Button
            variant="solid"
            color="primary"
            onClick={onAddRow}
            style={{ marginRight: 16 }}
            startDecorator={<AddIcon />}
          >
            Add Row
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={onRemoveRow}
            startDecorator={<RemoveIcon />}
          >
            Remove Row
          </Button>
        </Box>
      ) : (
        <IconButton
          variant="solid"
          color="primary"
          onClick={() => {
            setCar({ ..._car.current });
          }}
          sx={{ mt: 2 }}
        >
          <RefreshIcon />
        </IconButton>
      )}
    </Box>
  );
}

export default CarRows;
