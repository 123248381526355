import { Box } from '@mui/joy';
import { PropsWithChildren } from 'react';
import Helmet from 'react-helmet';
import Footer from './Footer';
import MySnackbar from './MySnackbar';

interface Props {
  title: string;
}

function PublicPageTemplate({
  title = 'Where do I sit?',
  children,
}: PropsWithChildren<Props>) {
  return (
    <Box
      style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box style={{ flex: 1 }}>{children}</Box>
      <Footer />
      <MySnackbar />
    </Box>
  );
}

export default PublicPageTemplate;
