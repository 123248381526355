import { Typography } from '@mui/joy';
import packageJson from '../../package.json';

function Version() {
  const version = packageJson?.version;
  if (version) {
    return <Typography>{`Version: ${version}`}</Typography>;
  }
  return null;
}

export default Version;
