import { Box, Button, Typography } from '@mui/joy';
import { useCallback, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import TextFieldElement from '../components/TextFieldElement';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface Props {
  showSetup: any;
  car: any;
  _car: any;
  setCar: any;
}

function KidsForm({ showSetup, car, _car, setCar }: Props) {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm<any>({
    defaultValues: {
      kids: car.kids,
    },
  });
  useEffect(() => {
    if (car) {
      setValue('kids', car.kids);
    }
  }, [setValue, car]);
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: 'kids',
    },
  );
  const onSubmit = useCallback(
    (values: any) => {
      setCar({ ..._car.current, kids: values.kids });
    },
    [_car, setCar],
  );
  return (
    showSetup && (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: 32 }}>
          <Typography level="h2" sx={{ mb: 1, textAlign: 'center' }}>
            Kids
          </Typography>
          {fields.map((field, index) => (
            <TextFieldElement
              key={field.id}
              control={control}
              name={`kids.${index}.name`}
              label={`Kid ${index + 1}'s name`}
              rules={[{ required: 'This field is required' }]}
              sx={{ mb: 1, minWidth: 300 }}
            />
          ))}
          <Box sx={{ mt: 3, mb: 2 }}>
            <Button
              variant="solid"
              color="primary"
              onClick={() => append({ name: '' })}
              style={{ marginRight: 8 }}
              startDecorator={<AddIcon />}
            >
              Add Kid
            </Button>
            {fields.length > 1 && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => remove(fields.length - 1)}
                startDecorator={<RemoveIcon />}
              >
                Remove Kid
              </Button>
            )}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="solid" color="primary" type="submit">
              Update Names
            </Button>
          </Box>
        </form>
      </Box>
    )
  );
}

export default KidsForm;
